<!-- 学校资料 -->
<template>
    <div class="schoolData">
        <el-form ref="form" :model="form" inline class="form" label-width="80px">
            <!-- <el-form-item label="编号">
                <el-input v-model="form.number" placeholder="请输入编号"></el-input>
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="类型">
                <el-select v-model="form.type" placeholder="请选择类型" :clearable="true">
                    <el-option v-for="item in form.typeChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.condition" placeholder="请选择状态" :clearable="true">
                    <el-option v-for="item in form.conditionChoose" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="" style="margin-left: 28px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="empty">
                    重置
                </el-button>
            </el-form-item>
        </el-form>
        <div class="" style="margin-left:22px ;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
                新增
            </el-button>
            <!-- <el-button size="small" class="daochu">
                导出
            </el-button> -->
        </div>
        <!-- 表格部分 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650" id="out-table" @sort-change="abc">
            <!-- 复选按钮 -->
            <el-table-column type="selection" align="center" width="50">
            </el-table-column>
            <el-table-column prop="number" sortable="custom" label="编号" align="center" width="150">
            </el-table-column>
            <el-table-column prop="name" sortable="custom" label="学校名称" align="center">
            </el-table-column>
            <el-table-column prop="abbreviation" sortable="custom" label="学校全称" align="center">
            </el-table-column>
            <el-table-column prop="area" sortable="custom" label="所在地区" align="center" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="type" label="学校类型" sortable="custom" align="center">
                <template slot-scope="scope">
                    <span v-if="scope.row.type==1">全部</span>
                    <span v-else-if="scope.row.type==2">小学</span>
                    <span v-else-if="scope.row.type==3">初中</span>
                    <span v-else-if="scope.row.type==4">高中</span>
                    <span v-else-if="scope.row.type==5">完中</span>
                    <span v-else-if="scope.row.type==6">大学</span>
                    <span v-else-if="scope.row.type==7">成人</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==1">可用</span>
                    <span v-else-if="scope.row.status==2">禁用</span>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="说明" sortable="custom" align="center" width="300"
             :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background: #18BC9C;color:#FFFFFF;" @click="edit(scope.row.id)">
                        编辑
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="dlt(scope.$index, scope.row.id)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50, 100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    number: "", //编号
                    name: "", //名称
                    type: "", //类型
                    typeChoose: [ //员工类型选择
                        {
                            label: "全部",
                            value: "1"
                        },
                        {
                            label: "小学",
                            value: "2"
                        },
                        {
                            label: "初中",
                            value: "3"
                        },
                        {
                            label: "高中",
                            value: "4"
                        },
                        {
                            label: "完中",
                            value: "5"
                        },
                        {
                            label: "大学",
                            value: "6"
                        },
                        {
                            label: "成人",
                            value: "7"
                        }
                    ],
                    conditionChoose: [{
                            label: "可用",
                            value: "1"
                        },
                        {
                            label: "禁用",
                            value: "2"
                        },
                    ], //状态选择
                    condition: "" ,//状态
                    search:""//搜索
                },
                tableData: [],
                currentPage: 1,
				currentLimit: 20,
                total: 0,
                arr:[]
            }
        },
        created() {
            this.getList()
        },
        activated() {
           // console.log(1); 
           this.getList()
        },
        methods:{
            empty(){//重置
                this.form.search=""
                this.getList()
            },
        	getList() {
        		this.$request({url: '/api/school/list', method: 'POST', data: {
        			page: this.currentPage,
        			limit: this.currentLimit,
        			site_number: this.form.number,
        			site_name: this.form.name,
        			status: this.form.condition,
                    fullname:this.form.search,
                    sort:this.arr
        		}}).then(res => {
        			this.tableData = res.data.list
        			this.total =res.data.count
        		})
        	},
            handleSizeChange(val) {
        		this.currentLimit = val
        		this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
        		this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addSchoolData"
                })
            },
            edit(id) { //编辑
                this.$router.push({
                    path: "editSchoolData",
					query:{id: id}
                })
            },
			// 查询
			query() {
                this.currentPage=1
				this.getList()
			},
			// 删除
			dlt(index, id) {
				var that = this
				this.$confirm('是否删除此条记录?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					that.$request({url: '/api/school/del', method: 'POST', data: {id}}).then(res => {
						if(res.code == 1) {
							that.$message({
							  message: res.msg,
							  type: 'success'
							});
							that.tableData.splice(index, 1) 
						}
					})
				})
			},
            //排序
            abc(i){ 
                console.log(i)
                this.tableData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr,111)
                this.getList()
            }

        }
    }
</script>

<style scoped="scoped">
    .schoolData {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
    }

    .form::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    ::v-deep .el-icon-download {
        font-weight: bold !important;
    }
    .daochu{
        padding-left: 30px;
        background: url(../../assets/export.png) 8px 5px no-repeat #FF7300;
        color: #FFFFFF;
    }
    /* 表格部分*/
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
